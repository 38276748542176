// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    // dark_indigo
    --ion-color-primary: #202F72;
    --ion-color-primary-rgb: 18, 32, 95;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #101c54;
    --ion-color-primary-tint: #2a366f;

    // clear_blue
    --ion-color-secondary: #2b83ff;
    --ion-color-secondary-rgb: 43, 131, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #2673e0;
    --ion-color-secondary-tint: #408fff;

    // salmon
    --ion-color-tertiary: #fa7876;
    --ion-color-tertiary-rgb: 250, 120, 118;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #dc6a68;
    --ion-color-tertiary-tint: #fb8684;

    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    // pale_lilac
    --ion-color-warning: #e4e6f1;
    --ion-color-warning-rgb: 228, 230, 241;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #c9cad4;
    --ion-color-warning-tint: #e7e9f2;

    // carnation_pink
    --ion-color-danger: #ff81a0;
    --ion-color-danger-rgb: 255, 129, 160;
    --ion-color-danger-contrast: #000000;
    --ion-color-danger-contrast-rgb: 0, 0, 0;
    --ion-color-danger-shade: #e0728d;
    --ion-color-danger-tint: #ff8eaa;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    // pale_grey
    --ion-color-medium: #f5f8fc;
    --ion-color-medium-rgb: 245, 248, 252;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #d8dade;
    --ion-color-medium-tint: #f6f9fc;

    // white
    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

    --ion-color-light-warning: #FACD76;
    --ion-color-light-indigo: #F3F6FB;
}

// Calendly customization
.calendly-overlay {
    margin-top: var(--ion-safe-area-top, 0);
    background-color: rgba(31, 31, 31, 0.6) !important;

    .calendly-popup {
        margin-top: var(--ion-safe-area-top, 0);
    }
}
